let form = document.getElementById("variciContactForm");

async function handleSubmit(event) {
    event.preventDefault();
    let status = document.getElementById("variciContactFormStatus");
    let formData = new FormData(event.target);

    await fetch(event.target.action, {
        method: form.method,
        body: formData,
        headers: {
            'Accept': 'application/json'
        }
    }).then(response => {
        if (response.ok) {
            status.classList.remove('d-none');
            setTimeout(() => {
                status.classList.add('d-none');
            }, 3000)
            status.innerHTML = "Mesajınızı aldık!";
            form.reset()
        } else {
            response.json().then(res => {
                status.classList.remove('d-none');
                setTimeout(() => {
                    status.classList.add('d-none');
                }, 3000)
                if (Object.hasOwn(res, 'errors')) {
                    status.innerHTML = res["errors"].map(error => error["message"]).join(", ")
                } else {
                    status.innerHTML = "Hata! Formunuzu gönderirken bir sorun oluştu!"
                }
            })
        }
    }).catch(() => {
        status.classList.remove('d-none');
        setTimeout(() => {
            status.classList.add('d-none');
        }, 3000)
        status.innerHTML = "Hata! Formunuzu gönderirken bir sorun oluştu!"
    });
}
form.addEventListener("submit", handleSubmit)